
import { mapGetters } from 'vuex';

export default {
  props: {
    hasDisclaimer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters('footer', ['getFooterStatus']),
    ...mapGetters('quiz', ['getHeaderStatus', 'getQuizVariant']),
    utmSource() {
      return this.getUtmObject().utm_source;
    },
    getFooterText() {
      return this.$t('landingPage.footer.text');
    },
  },
};
